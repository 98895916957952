import Vue from 'vue'

import moment from 'moment'
import { toISOLocal } from '@/plugins/utils'

Vue.filter('fullDateTime', (value) => {
  if (!value || value === '' || !moment(value, moment.ISO_8601, true).isValid()) {
    try {
      const unixTime = parseInt(value)
      if (!unixTime) return value
      return moment(toISOLocal(new Date(unixTime * 1000))).format('DD.MM.YYYY. HH:mm')
    } catch {
      return value
    }
  }

  return moment(value).format('DD.MM.YYYY. HH:mm')
})

Vue.filter('unixToFullDateTime', (value) => {
  if (!value || value === '' || !moment(value, moment.ISO_8601, true).isValid()) {
    return value
  }

  return moment(value).format('DD.MM.YYYY. HH:mm')
})

Vue.filter('fullDate', (value) => {
  if (!value || value === '' || !moment(value, moment.ISO_8601, true).isValid()) {
    try {
      const unixTime = parseInt(value)
      if (!unixTime) return value
      return moment(toISOLocal(new Date(unixTime * 1000))).format('DD.MM.YYYY.')
    } catch {
      return value
    }
  }

  return moment(value).format('DD.MM.YYYY.')
})
