export default {
  methods: {
    getMessageByKey (key) {
      if (!key) {
        return ''
      }
      const keys = {
        'auth/user-not-found': this.$t('$vuetify.errors.noCorrespondingUser'),
        'auth/wrong-password': this.$t('$vuetify.errors.wrongPassword'),
        'auth/too-many-requests': this.$t('$vuetify.errors.tooManyRequests'),
        'auth/user-disabled': this.$t('$vuetify.errors.accountDisabled')
      }

      if (keys[key]) {
        return keys[key]
      } else {
        return ''
      }
    }
  }
}
