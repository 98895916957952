<template>
  <button
    style="padding: 0 0 3px 5px"
    @click="callback(helpMode, helpTag, $event)"
  >
    <v-icon color="blue">mdi-help-circle-outline</v-icon>
    {{ buttonText }}
  </button>
</template>

<script>
import EventBus from '@/plugins/event-bus'

export default {
  name: 'HelpButton',
  props: {
    helpMode: String,
    helpTag: String,
    buttonText: String
  },
  methods: {
    callback: function (mode, tag, event) {
      EventBus.$emit('helper-popup', mode, tag)
      if (event) {
        event.preventDefault()
      }
    }
  }
}
</script>
