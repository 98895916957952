import { auth } from '@/plugins/firebase'
import Vue from 'vue'
import VueRouter from 'vue-router'
import cashRegister from './cash-register'
import companies from './companies'
import company from './company'
import pointOfSale from './point-of-sale'

Vue.use(VueRouter)
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/components/Login')
  },
  {
    path: '/loginStage',
    name: 'LoginStage',
    component: () => import(/* webpackChunkName: "login" */ '@/components/LoginStage')
  },
  {
    path: '/helpdesk',
    name: 'Helpdesk',
    beforeEnter: () => {
      window.location.href = 'https://aduroidea.com/helpdesk'
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Business" */ '@/modules/home/Home'),
    redirect: { name: 'companies' },
    meta: {
      requiresAuth: true,
      sideNav: 'Companies',
      topBar: 'CompaniesTopBar'
    },
    children: [
      companies,
      company,
      pointOfSale,
      cashRegister,
      {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "Users" */ '@/modules/companies/components/users/Users'),
        meta: {
          title: '$vuetify.users.titleText',
          addItemText: '$vuetify.users.addUser',
          addItemAction: 'addUser',
          filterSearch: false,
          requiresAuth: true,
          labelText: '$vuetify.users.searchLabelText',
          sideNav: 'Companies',
          topBar: 'CompaniesTopBar'
        }
      },
      {
        path: '/instructions',
        name: 'instructions',
        component: () => import(/* webpackChunkName: "Users" */ '@/modules/companies/components/instructions/Instructions'),
        meta: {
          noAddButton: true,
          removeSearch: true,
          removeGrid: true,
          title: '$vuetify.instructions.titleText',
          filterSearch: false,
          requiresAuth: true,
          sideNav: 'Companies',
          topBar: 'CompaniesTopBar'
        }
      }
    ]
  },

  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: () => import(/* webpackChunkName: "login" */ '@/components/PasswordReset')
  },
  {
    path: '/posd',
    name: 'Posd',
    component: () => import(/* webpackChunkName: "Business" */ '@/modules/posd/Posd')
  },
  {
    path: '*',
    name: 'catchall',
    component: () => import(/* webpackChunkName: "error404" */'@/components/error404')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  if (requiresAuth && (!auth.currentUser || !auth.currentUser.emailVerified)) {
    if (process.env.VUE_APP_FIREBASE_PROJECT_ID === 'ai-fisk-stage') {
      next({ name: 'LoginStage' })
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})
export default router
