
import state from '@/state'
import { auth, df } from '@/plugins/firebase'
export default {
  path: '/companies/:companyId',
  name: 'company',
  component: () => import(/* webpackChunkName: "PointsOfSale" */ '@/modules/company/Company'),
  beforeEnter: async (to, from, next) => {
    // state.removePointOfSale()
    if (to.params.companyId !== state.getCurrentCompany().id) {
      const companies = await df.doc(`user_companies/${auth.currentUser.uid}`).get()
      if (
        !companies ||
        !companies.exists ||
        !companies.data() ||
        Object.keys(companies.data().companies).length < 1 ||
        !companies.data().companies[to.params.companyId] ||
        companies.data().companies[to.params.companyId].status !== 'OK'
      ) {
        next({ name: 'catchall' })
        return
      }
      const company = companies.data().companies[to.params.companyId]
      state.setCurrentCompany(company)
      state.setAppTitle(company.name)
      next()
    } else {
      next()
    }
  },
  meta: {
    requiresAuth: true
  },
  redirect: (to) => {
    if (to.path === `/companies/${to.params.companyId}`) {
      return `/companies/${to.params.companyId}/locations`
    } else {
      return to.path
    }
  },
  children: [
    {
      path: '/companies/:companyId/locations',
      name: 'company.pointsOfSale',
      meta: {
        title: '$vuetify.companyTabs.pointsOfSale',
        addItemText: '$vuetify.company.addPointOfSaleBtn',
        addItemAction: 'openAddPointOfSaleForm',
        filterSearch: true,
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      },
      component: () => import(/* webpackChunkName: "PointsOfSale" */ '@/modules/company/components/points-of-sale/PointsOfSale')
    },
    {
      path: '/companies/:companyId/items',
      name: 'company.items',
      meta: {
        title: '$vuetify.companyTabs.lager',
        addItemText: '$vuetify.company.addLagerItemBtn',
        addItemAction: 'openaddItemForm',
        noAddButton: true,
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/items/lager',
      name: 'company.items.lager',
      meta: {
        title: '$vuetify.companyTabs.lager',
        addItemText: '$vuetify.company.addLagerItemBtn',
        addItemAction: 'openaddItemForm',
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/lager/Lager')
    },
    {
      path: '/companies/:companyId/items/upload',
      name: 'company.items.upload',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/company/components/lager/UploadItems'),
      meta: {
        title: '$vuetify.uploadItems.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/items/discount',
      name: 'company.items.discount',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/company/components/discount/Discount'),
      meta: {
        title: '$vuetify.discount.titleText',
        addItemText: '$vuetify.company.addDiscountBtn',
        addItemAction: 'openaddDiscount',
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/items/categories',
      name: 'company.items.categories',
      meta: {
        title: '$vuetify.companyTabs.categories',
        addItemText: '$vuetify.company.addCategory',
        addItemAction: 'openAddCategoryForm',
        requiresAuth: true,
        filterSearch: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      },
      component: () => import(/* webpackChunkName: "Categories" */ '@/modules/company/components/categories/Categories')
    },
    {
      path: '/companies/:companyId/receipts',
      name: 'company.receipts',
      meta: {
        title: '$vuetify.companyTabs.receipts',
        noAddButton: true,
        removeSearch: true,
        removeGrid: true,
        requiresAuth: true,
        filterSearch: false,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/receipts/Receipts')
    },
    {
      path: '/companies/:companyId/associates',
      name: 'company.associates',
      meta: {
        title: '$vuetify.companyTabs.associates',
        addItemText: '$vuetify.company.addAssociate',
        addItemAction: 'addAssociate',
        requiresAuth: true,
        filterSearch: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/associates/Associates')
    },
    {
      path: '/companies/:companyId/users',
      name: 'company.users',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/companies/components/users/Users'),
      meta: {
        title: '$vuetify.users.titleText',
        addItemText: '$vuetify.users.addUser',
        addItemAction: 'addUser',
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/reports',
      name: 'company.reports',
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/reports/reportPosd',
      name: 'company.reports.reportPosd',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/companies/components/reports/ReportPosd'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    // {
    //   path: '/companies/:companyId/instructions',
    //   name: 'company.instructions',
    //   component: () => import(/* webpackChunkName: "Users" */ '@/modules/companies/components/instructions/Instructions'),
    //   meta: {
    //     title: '$vuetify.instructions.titleText',
    //     filterSearch: false,
    //     requiresAuth: true,
    //     noAddButton: true,
    //     removeSearch: true,
    //     removeGrid: true,
    //     sideNav: 'Company',
    //     topBar: 'CompanyTopBar'
    //   }
    // },
    {
      path: '/companies/:companyId/warehouseItems',
      name: 'company.warehouseModule.items',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/WarehouseItems'),
      meta: {
        removeGrid: true,
        noAddButton: true,
        addItemText: '$vuetify.warehouse.addItemsText',
        addItemAction: 'openAddWarehouseModuleItemForm',
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/warehouseDocuments',
      name: 'company.warehouseModule.documents',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/WarehouseDocuments'),
      meta: {
        noAddButton: true,
        removeSearch: true,
        removeGrid: true,
        title: '$vuetify.warehouse.titleText',
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/warehouseDocumentsv2',
      name: 'company.warehouseModule.documentsv2',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/WarehouseDocumentsv2'),
      meta: {
        noAddButton: true,
        removeSearch: true,
        removeGrid: true,
        title: '$vuetify.warehouse.titleText',
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/items/norm',
      name: 'company.items.norm',
      meta: {
        title: '$vuetify.companyTabs.norm',
        noAddButton: true,
        removeSearch: true,
        // addItemText: '$vuetify.company.addNormBtn',
        // addItemAction: 'openaddItemForm',
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/norm/Norm')

    },
    {
      path: '/companies/:companyId/userdata/bill',
      name: 'userData.bill',
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/userdata/Bill')
    },
    {
      path: '/companies/:companyId/reports/groupageColection',
      name: 'company.reports.groupageColection',
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      },
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/companies/components/reports/GroupageCollection')
    },
    {
      path: '/companies/:companyId/orders/createOrder',
      name: 'company.orders.createOrder',
      component: () => import(/* webpackChunkName: "Orders" */ '@/modules/point-of-sale/components/orders/CreateOrder'),
      meta: {
        removeGrid: true,
        addItemText: '$vuetify.orders.createOrder',
        addItemAction: 'openCreateOrder',
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/orders/showOrders',
      name: 'company.orders.showOrders',
      component: () => import(/* webpackChunkName: "Orders" */ '@/modules/point-of-sale/components/orders/ShowOrders'),
      meta: {
        removeGrid: true,
        addItemText: '$vuetify.orders.showOrders',
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/restauraunt/menuItems',
      name: 'company.restaurant.menuItems',
      component: () => import(/* webpackChunkName: "Restaraunt" */ '@/modules/point-of-sale/components/menu-item/MenuItems'),
      meta: {
        title: '$vuetify.restaurant.menu',
        filterSearch: false,
        requiresAuth: true,
        addItemAction: 'openAddMenuItem',
        addItemText: 'Dodaj meni',
        removeSearch: true,
        removeGrid: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/suppliers',
      name: 'company.suppliers',
      meta: {
        title: '$vuetify.companyTabs.suppliers',
        addItemText: '$vuetify.company.addSupplier',
        addItemAction: 'addSupplier',
        requiresAuth: true,
        filterSearch: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      },
      component: () => import(/* webpackChunkName: "Lager" */ '@/modules/company/components/suppliers/Suppliers')
    },
    {
      path: '/companies/:companyId/all-reports/reportMenu',
      name: 'company.all-reports.reportMenu',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/ReportMenu'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/all-reports/recapitulation',
      name: 'company.all-reports.recapitulation',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/RecapitulationTrade'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/all-reports/recapitulationBuyers',
      name: 'company.all-reports.recapitulationBuyers',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/RecapitulationBuyers'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/all-reports/taxReport',
      name: 'company.all-reports.taxReport',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/TaxReport'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/all-reports/pnpReport',
      name: 'company.all-reports.pnpReport',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/PnpReport'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/all-reports/recapitulationTips',
      name: 'company.all-reports.recapitulationTips',
      component: () => import(/* webpackChunkName: "Users" */ '@/modules/all-reports/RecapitulationTips'),
      meta: {
        title: '$vuetify.reports.titleText',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        labelText: '$vuetify.users.searchLabelText',
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/orders/receiveOrder',
      name: 'company.orders.receiveOrder',
      component: () => import(/* webpackChunkName: "Orders" */ '@/modules/point-of-sale/components/orders/other/receive-order/ReceiveOrderOther'),
      meta: {
        removeGrid: true,
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/warehouseDocumentsOverview',
      name: 'company.warehouseModule.warehouseDocumentsOverview',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/WarehouseDocumentsOverview'),
      meta: {
        removeGrid: true,
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/posConfiguration',
      name: 'company.posConfiguration',
      component: () => import(/* webpackChunkName: "" */ '@/modules/point-of-sale/components/pos-configuration/PosConfiguration'),
      meta: {
        title: '$vuetify.posConfig.title',
        filterSearch: false,
        requiresAuth: true,
        addItemAction: 'openAddPos',
        addItemText: 'Dodaj pos',
        removeSearch: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/cardConfiguration',
      name: 'company.cardConfiguration',
      component: () => import(/* webpackChunkName: "" */ '@/modules/point-of-sale/components/card-configuration/CardConfiguration'),
      meta: {
        title: '$vuetify.cardConfig.title',
        filterSearch: false,
        requiresAuth: true,
        addItemAction: 'openAddCard',
        addItemText: 'Dodaj karticu',
        removeSearch: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/activeInventory',
      name: 'company.warehouseModule.activeInventory',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/Inventura/ActiveInventory'),
      meta: {
        title: '$vuetify.inventura.active',
        removeGrid: true,
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/inventories',
      name: 'company.warehouseModule.inventories',
      component: () => import(/* webpackChunkName: "Warehouse" */ '@/modules/point-of-sale/components/warehouse/warehouse-module/Inventura/Inventories'),
      meta: {
        title: '$vuetify.inventura.title',
        removeGrid: true,
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/communicationConfiguration',
      name: 'company.communicationConfiguration',
      component: () => import(/* webpackChunkName: "" */ '@/modules/point-of-sale/components/communication-configuration/CommunicationConfiguration'),
      meta: {
        title: '$vuetify.communicationConfig.title',
        filterSearch: false,
        requiresAuth: true,
        addItemAction: 'openAddCommunication',
        addItemText: '$vuetify.add',
        removeSearch: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/notifications',
      name: 'company.notifications',
      component: () => import(/* webpackChunkName: "" */ '@/modules/company/components/notifications/Notifications'),
      meta: {
        title: '$vuetify.notifications.title',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        removeSearch: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/admin/companySettings',
      name: 'company.admin.companySettings',
      component: () => import(/* webpackChunkName: "" */ '@/modules/company/components/admin/CompanySettings'),
      meta: {
        title: '$vuetify.companySettings.title',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        removeSearch: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    },
    {
      path: '/companies/:companyId/admin/companyAdd',
      name: 'company.admin.companyAdd',
      component: () => import(/* webpackChunkName: "" */ '@/modules/company/components/admin/CompanyAdd'),
      meta: {
        title: '$vuetify.companyAdd.title',
        noAddButton: true,
        filterSearch: false,
        requiresAuth: true,
        removeSearch: true,
        sideNav: 'Company',
        topBar: 'CompanyTopBar'
      }
    }
  ]
}
