<template>
  <v-dialog
    v-model="visible"
    persistent
    style="position: absolute; z-index: 7"
    width="450"
    retain-focus
  >
    <v-card :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
      <v-card-title
        style="background-color: red; font-weight: bold; color: white"
      >
        <span class="text-h5" style="font-weight: bold"
          >Brisanje korisničkog računa</span
        >
      </v-card-title>

      <v-card-text style="color: black">
        <span v-html="deleteText"></span>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          @click="onCancelClick"
          id="success"
          class="btn successButton"
          color="#757575"
        >
          Odustani
        </v-btn>
        <v-btn @click="onOkClick" class="btn dangerButton" color="#757575"
          >Obriši</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { auth, functions } from '@/plugins/firebase'
import logOutUser from '@/mixins/logOutUser'

export default {
  name: 'AccountDelete',
  mixins: [logOutUser],
  data: () => ({
    deleteText: 'Ovim ćete obrisati vaš korisnički račun i sve podatke. <br/><b>Jeste li sigurni?</b> <br/> Za dodatne informacije obratite nam se na <br/> broj telefona <b>+385955193973</b> ili <br/> email <b>support@aikasa.app</b>',
    visible: false
  }),
  methods: {
    open () {
      this.visible = true
      this.$emit('show')
    },
    hide () {
      this.visible = false
    },
    onOkClick () {
      var userapi = functions.httpsCallable('userapi')
      var userID = auth.currentUser.uid
      userapi({
        action: {
          operation: 'delete',
          entity: 'user',
          params: {
            user_id: userID
          }
        }
      })
        .then((result) => {
          console.log(result)
          this.logOut()
        })
      this.visible = false
    },
    onCancelClick () {
      this.visible = false
    }
  }
}
</script>
