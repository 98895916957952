<template>
  <div>
    <v-app-bar
      style="z-index: 1; background-color: transparent; box-shadow: none"
    >
      <v-spacer></v-spacer>
      <v-text-field
        v-model.trim="searchString"
        dense
        filled
        rounded
        clearable
        :placeholder="searchPlaceholder"
        prepend-inner-icon="mdi-magnify"
        class="shrink expanding-search"
        :class="{ closed: searchBoxClosed && !searchString }"
        @focus="searchBoxClosed = false"
        @blur="setBlur()"
        @keyup.enter="callback(emitDestination, $event)"
        @click:clear="clearCallback(emitDestination, $event)"
      ></v-text-field>
    </v-app-bar>
  </div>
</template>
<script>

import EventBus from '@/plugins/event-bus'
import triGram from '@/mixins/trigram'
import state from '@/state'

export default {
  name: 'SearchBox',
  mixins: [triGram],
  data: function () {
    return {
      searchString: '',
      searchBoxClosed: false,
      searchObject: {

      }
    }
  },
  props: {
    emitDestination: String,
    searchPlaceholder: String
  },
  watch: {
    searchString (nv) {
      if (nv === '') {
        this.clearCallback(this.emitDestination)
      }
    },
    searchType (val) {
      this.searchBoxClosed = val
      this.$forceUpdate()
    }
  },
  mounted () {
    var searchType = state.getSearchType()
    this.searchBoxClosed = searchType
  },
  computed: {
    searchType () {
      return state.searchType
    }
  },

  methods: {
    setBlur () {
      if (state.getSearchType() === true) {
        this.searchBoxClosed = true
      }
    },
    clearCallback: function (emitDestination, event) {
      this.searchString = ''
      this.searchObject.searchString = ''
      this.searchObject.triGramObject = undefined
      EventBus.$emit(emitDestination, this.searchObject)
    },
    callback: function (emitDestination, event) {
      if (this.searchString !== '') {
        this.searchObject.searchString = this.searchString
        this.searchObject.triGramObject = this.triGram(this.searchString)
        EventBus.$emit(emitDestination, this.searchObject)
      }
    }
  }
}
</script>
<style>
.v-input.expanding-search {
  transition: max-width 0.5s !important;
  opacity: 1 !important;
  background: white !important;
  height: 40px;
  font-weight: bold;
}

.v-input.expanding-search.closed {
  max-width: 70px !important;
}
</style>
