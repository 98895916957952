<template>
  <div>
    <modal
      scrollable
      name="dialog"
      draggable
      height="auto"
      resizable
      style="z-index: 200"
      width="45%"
      content-class="my-custom-dialog"
    >
      <v-card style="padding: 15px">
        <v-card-title
          style="padding: 0 15px 15px 15px; font-weight: 700; cursor: move"
          class="text-h5"
        >
          <v-row>
            <div
              style="margin: 10px 0 10px 0; font-family: revert"
              v-html="decodeBase64(this.main_title)"
            ></div>
            <v-spacer></v-spacer>

            <v-icon @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-row style="padding: 16px 1px 10px 1px; font-size: 16px">
            <div v-html="decodeBase64(this.main_description)"></div>
          </v-row>
          <v-row justify="center">
            <v-expansion-panels accordion v-model="panel">
              <v-expansion-panel v-for="(item, i) in this.panels" :key="i">
                <v-expansion-panel-header
                  style="
                    font-weight: bolder;
                    font-size: 16px;
                    font-family: revert;
                  "
                  >{{ decodeBase64(item.title) }}</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div
                    v-html="decodeBase64(item.description)"
                    class="font-weight:300;font-size:18px"
                  ></div>
                  <div
                    v-if="item.subtitle"
                    v-html="decodeBase64(item.subtitle)"
                    style="font-size: 20px; margin: 0 0 10px 0"
                  ></div>
                  <div
                    style="font-size: 16px"
                    v-html="decodeBase64(item.help_text)"
                  ></div>
                  <div v-if="item.panels">
                    <v-expansion-panels accordion>
                      <v-expansion-panel
                        v-for="(itema, i) in item.panels"
                        :key="i"
                      >
                        <v-expansion-panel-header
                          style="
                            font-weight: bolder;
                            font-size: 16px;
                            font-family: revert;
                          "
                          >{{
                            decodeBase64(itema.title)
                          }}</v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                          <div
                            v-html="decodeBase64(itema.description)"
                            class="font-weight:300;font-size:18px"
                          ></div>
                          <div
                            v-if="item.subtitle"
                            v-html="decodeBase64(itema.subtitle)"
                            style="font-size: 20px; margin: 0 0 10px 0"
                          ></div>
                          <div
                            style="font-size: 16px"
                            v-html="decodeBase64(itema.help_text)"
                          ></div>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-card-text>
      </v-card>
    </modal>
    <v-dialog
      v-model="helpDefined"
      width="250px"
      style="padding: 15px"
      content-class="my-custom-dialog"
    >
      <v-card>
        <div style="text-align: center; padding: 15px">
          <v-img
            style="text-align: center"
            src="@/assets/logo.png"
            contain
          ></v-img>
          Verzija {{ version }}
        </div>
      </v-card></v-dialog
    >
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import state from '@/state'
export default {
  name: 'Help',
  data: () => ({
    panels: '',
    main_title: '',
    main_description: '',
    data: '',
    panel: undefined,
    helpDefined: false
  }),
  computed: {
    version () {
      return state.getVersion() + '.' + state.getSubversion()
    }
  },
  methods: {
    close () {
      this.$modal.hide('dialog')
    },
    decodeBase64 (str) {
      var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
      if (base64regex.test(str)) {
        return window.atob(str)
      }
      return str
    },
    searchPanel (tag) {
      this.panels.forEach((panels, key) => {
        if (panels.tag === tag) { this.panel = key }
      })
    },
    async showHelp (mode, tag) {
      await this.getHelpData(mode)
      this.main_title = this.data.main_title
      this.main_description = this.data.main_description
      this.panels = this.data.panels
      if (tag) { this.searchPanel(tag) }
    },
    async getHelpData (mode) {
      const help = await df.doc(`help/${mode}`).get()
      if (help && help.data()) {
        const doc = help.data()
        this.data = doc
        this.$modal.show('dialog')
      } else { this.helpDefined = true }
    }
  }
}
</script>

<style scoped>
div >>> .my-custom-dialog {
  align-self: flex-start;
  max-width: 250px;
}
</style>
