<template>
  <div v-if="loading" class="text-center">
    <v-overlay
      v-if="loading"
      :absolute="absolute"
      opacity="1"
      :value="overlay"
      style="z-index: 100"
    >
      <v-row class="justify-center">
        <v-img
          v-bind:src="logo"
          class="ma-10 d-flex justify-center align-center align-center"
          max-width="250"
          contain
        ></v-img
      ></v-row>
      <p class="font-align">
        Potrebno je ažurirati aplikaciju na novu verziju.
      </p>
      <p
        class="font-align"
        v-if="currentVersion !== undefined && latestVersion !== undefined"
      >
        Trenutna verzija: {{ currentVersion }}<br />
        Aktualna verzija: {{ latestVersion }}
      </p>

      <p class="font-align">
        Windows/Linux:<br />
        • Držite tipku Ctrl i pritisnite tipku F5.<br />
        • Ili držite Ctrl i ⇧ Shift i zatim pritisnite R.<br />
        Mac:<br />
        • Držite pritisnut ⇧ Shift i kliknite gumb Reload.<br />
        • Ili držite ⌘ Cmd i ⇧ Shift i zatim pritisnite R.
      </p>
    </v-overlay>
  </div>
</template>

<script>

export default {
  name: 'Maska',
  data: () => ({
    mask: false,
    text: undefined,
    absolute: true,
    op: 1,
    overlay: true,
    additionalText: undefined,
    additionalTextTimer: undefined,
    currentVersion: undefined,
    latestVersion: undefined
  }),
  computed: {
    loading () {
      return this.mask
    },
    logo () {
      let ret = 'logo-AI-kasa-negativ.svg'
      try {
        ret = require('@/assets/' + this.imageSrc)
      } catch (err) {
        if (this.$vuetify.theme.dark === true) { ret = require('@/assets/' + 'logo-AI-kasa-negativ.svg') } else { ret = require('@/assets/' + 'logo-AI-kasa-negativ.svg') }
        return ret
      }
      return ret
    }
  },
  methods: {
    span (text) {
      return `<span> ${text} </span>`
    },
    show (currentVersion = undefined, latestVersion = undefined) {
      this.currentVersion = currentVersion
      this.latestVersion = latestVersion

      this.mask = true
    },
    hide () {
      this.mask = false
      this.op = 0.46
      this.absolute = true
      this.overlay = true
    }
  }
}
</script>
<style scoped>
.font-align {
  font-size: 20px;
  text-align: left;
}
</style>
