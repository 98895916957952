import en from './locales/en'
import hr from './locales/hr'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
const messages = {
  en: en,
  hr: hr
}

const numberFormats = {
  USD: {
    currency: {
      style: 'currency',
      currency: 'USD'
    }
  },
  HRK: {
    currency: {
      style: 'currency',
      currency: 'HRK'
    }
  },
  EUR: {
    currency: {
      style: 'currency',
      currency: 'EUR'
    }
  },
  GBP: {
    currency: {
      style: 'currency',
      currency: 'GBP'
    }
  }
}

const i18n = new VueI18n({
  locale: 'hr',
  messages,
  numberFormats,
  silentTranslationWarn: process.env.NODE_ENV === 'production'
})

export default i18n
