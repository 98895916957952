import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY || 'api-key-not-set',
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || 'api-domain-not-set',
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL || 'env-not-set',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || 'env-not-set',
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || 'env-not-set',
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || 'env-not-set',
  appId: process.env.VUE_APP_FIREBASE_APP_ID || 'env-not-set'
}
// messaging.onMessage((payload) => {
//     console.log('Message received. ', payload);
//     // ...
//   })

firebase.initializeApp(firebaseConfig)

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
})

firebase.firestore().enablePersistence()
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {

      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  })

const auth = firebase.auth()

const df = firebase.firestore()

const functions = firebase.app().functions('europe-west1')

export {
  auth,
  df,
  functions,
  firebase
}
