<template>
  <div v-if="loading" class="text-center">
    <v-overlay
      v-if="loading"
      :absolute="absolute"
      :opacity="opacity"
      :value="overlay"
      style="z-index: 100"
    >
      <div class="blue--text" style="font-size: 78px">
        {{ text !== undefined ? text : "" }}
      </div>
      <v-progress-circular
        indeterminate
        :size="!text ? 64 : 86"
        color="primary"
      >
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>

export default {
  name: 'Loader',
  data: () => ({
    loader: false,
    text: undefined,
    absolute: true,
    opacity: 0.46,
    overlay: true,
    additionalText: undefined,
    additionalTextTimer: undefined
  }),
  computed: {
    loading () {
      return this.loader
    }
  },
  methods: {
    show (text = undefined, custom = undefined) {
      this.text = text

      if (custom) {
        if (custom.absolute) {
          this.absolute = custom.absolute
        }
        if (custom.overlay) {
          this.overlay = custom.overlay
        }
        if (custom.opacity) {
          this.opacity = custom.opacity
        }
        if (custom.additionalText) {
          this.additionalText = custom.additionalText
        }

        if (custom.additionalTextTimer) {
          this.additionalTextTimer = custom.additionalTextTimer
        }

        if (custom.additionalTextTimer) {
          let timer = 3500
          if (this.additionalTextTimer > timer) {
            timer = this.additionalTextTimer
          }

          const interval = setInterval(() => {
            this.text = this.additionalText
            clearInterval(interval)
          }, timer)
          this.additionalTextTimer = undefined
        }
      }
      this.loader = true
    },
    hide () {
      this.loader = false
      this.opacity = 0.46
      this.absolute = true
      this.overlay = true
    }
  }
}
</script>
