
import { auth, functions } from '@/plugins/firebase'
import state from '@/state'
export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    countries: []
  }),
  methods: {
    logOut () {
      this.showLoader()
      var EndSession = functions.httpsCallable('EndSession')
      EndSession({})
        .finally(() => {
          auth.signOut().then(() => {
            state.isAuthenticated = false
            state.removeCurrentCompany()
            state.removePointOfSale()
            state.removeCashRegister()
            state.removeAppTitle()
            state.removeUser()
            state.setListView(true)
            this.hideLoader()
            if (process.env.VUE_APP_FIREBASE_PROJECT_ID === 'ai-fisk-stage') {
              this.$router.push({ path: '/loginStage' })
            } else {
              this.$router.push({ path: '/login' })
            }
          }).catch((error) => {
            this.hideLoader()
            // eslint-disable-next-line no-console
            // console.error(error)
            this.showMsgBox({
              text: error || 'Internal error',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            // this.showMsgBox({ title: 'Error', text: 'Exception occured while performing logout', color: 'error' })
          })
        })
    }
  }
}
