import vuetify from '@/plugins/vuetify'

const rules = {
  lt (target, message = `Value has to be less than ${target}`) {
    return val => {
      return !val || val < target || message
    }
  },

  gt (target, message = `Vrijednost mora biti veća od ${target}`) {
    return val => {
      return !val || parseFloat(val) > parseFloat(target) || message
    }
  },

  eq (target, message = `Value has to be equal to ${target}`) {
    return val => {
      return !val || val === target || message
    }
  },

  diff (target, message = `Value has to be different than ${target}`) {
    return val => {
      return !val || val !== target || message
    }
  },

  lteq (target, message = `Value has to be less than or equal to ${target}`) {
    return val => {
      return !val || val <= target || message
    }
  },

  gteq (target, message = `Vrijednost mora biti jednaka ili veća od ${target}`) {
    return val => {
      return !val || parseFloat(val) >= parseFloat(target) || message
    }
  },

  req (message = vuetify.userPreset.lang.t('$vuetify.formDetails.requiredField')) {
    return val => {
      return (!!val || val === 0) || message
    }
  },

  fileReq (message = vuetify.userPreset.lang.t('$vuetify.formDetails.requiredField')) {
    return val => {
      return (val.name !== undefined && val.name !== '') || message
    }
  },

  arrReq (message = vuetify.userPreset.lang.t('$vuetify.formDetails.requiredField')) {
    return val => {
      return (!!val && !!val.length > 0) || message
    }
  },

  minLen (target, message = `Korisničko ime mora imati najmanje ${target} znaka.`) {
    return val => {
      return target <= val?.length || message
    }
  },

  maxLen (target, message = `Najveća dozvoljena veličina je ${target}`) {
    return val => {
      return !val || val.length <= target || message
    }
  },

  rx (regex, message = 'Pogrešan unos') {
    return val => {
      return !val || !!val.match(regex) || message
    }
  },

  email (message = vuetify.userPreset.lang.t('$vuetify.formDetails.emailValid')) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\u00C0-\u024F\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return val => {
      return !val || !!val.match(regex) || message
    }
  },

  isEmail () {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\u00C0-\u024F\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return val => {
      return !val || !!val.match(regex)
    }
  },

  username (message = vuetify.userPreset.lang.t('$vuetify.formDetails.usernameValid')) {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))$/
    return val => {
      return !val || !!val.match(regex) || message
    }
  },

  money (message = vuetify.userPreset.lang.t('$vuetify.formDetails.numberValid')) {
    const regex = /^(-[0-9]+|([0-9]+))(|[.][0-9]{1,2})$/
    return val => {
      return !val || !!val.match(regex) || message
    }
  },

  oib (message = vuetify.userPreset.lang.t('$vuetify.formDetails.oibValid')) {
    return (val) => {
      const oibCheck = function (oibCode) {
        var checkDigit, num

        var code = oibCode.toString()

        if (code.length === 13 && code.substr(0, 2).toUpperCase() === 'HR') {
          code = code.substr(2, 11)
        } else if (code.length !== 11) {
          return false
        }

        var numCheck = parseInt(code, 10)
        if (isNaN(numCheck)) {
          return false
        }

        num = 10
        for (var i = 0; i < 10; i++) {
          num = num + parseInt(code.substr(i, 1), 10)
          num = num % 10
          if (num === 0) {
            num = 10
          }
          num *= 2
          num = num % 11
        }

        checkDigit = 11 - num
        if (checkDigit === 10) {
          checkDigit = 0
        }

        return parseInt(code.substr(10, 1), 10) === checkDigit
      }

      // let regExp = new RegExp('^[0-9]{11}$')
      return (!val || oibCheck(val)) || message
    }
  },

  validPass (options = [], message = '') {
    if (!Array.isArray(options)) options = [options]
    return val => {
      let valid = true
      const sequenceNumbers = ['0123456789', '9876543210']
      if (sequenceNumbers.includes(val)) {
        valid = false
        message = vuetify.userPreset.lang.t('$vuetify.errors.simpleSequence')
      }
      if (val && val.length < 8) {
        valid = false
        message = vuetify.userPreset.lang.t('$vuetify.errors.shortPassword')
      }
      if (options.some(str => str && val?.includes(str))) {
        valid = false
        message = vuetify.userPreset.lang.t('$vuetify.errors.passwordContains')
      }

      if (/^\d+$/.test(val)) {
        valid = false
        message = vuetify.userPreset.lang.t('$vuetify.errors.passwordNumbers')
      }

      return valid || message
    }
  },
  nameValid (message = vuetify.userPreset.lang.t('$vuetify.errors.noLeadingSpaces')) {
    return val => {
      return (!!val && !!val.length > 0 && val.charAt(0) !== ' ') || message
    }
  }
}

export default rules
