import '@/filters/date'
import '@/filters/format'
import defaults from '@/mixins/defaults'
import i18n from '@/plugins/i18n'
import VueMask, { VueMaskDirective } from 'v-mask'
import { Money } from 'v-money'
import Vue from 'vue'
import 'vue-lazy-youtube-video/dist/style.simplified.css'
import VueTouchKeyboard from 'vue-touch-keyboard'
import 'vue-touch-keyboard/dist/vue-touch-keyboard.css' // load default style
import App from './App.vue'
import { auth } from './plugins/firebase'
import vuetify from './plugins/vuetify'
import router from './router'
import './sass/variables.scss'
import VModal from 'vue-js-modal'
import VueHead from 'vue-head'
import HelpButton from '@/components/HelpButton'
import SearchBox from '@/components/SearchBox'
import '@/assets/styles/main.css'
import 'tabulator-tables/dist/css/tabulator_simple.min.css'

import VueNumericInput from 'vue-numeric-input'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

Vue.use(VModal)

Vue.use(VueMask, {
  placeholders: {
    '#': null
  }
})
Vue.use(VueHead)

Vue.use(VueNumericInput)

Vue.use(VueTouchKeyboard)
Vue.directive('mask', VueMaskDirective)
Vue.directive('money', Money)
Vue.config.productionTip = false
Vue.component('HelpButton', HelpButton)
Vue.component('SearchBox', SearchBox)

let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      components: { 'vue-touch-keyboard': VueTouchKeyboard.component },
      router,
      vuetify,
      mixins: [defaults],
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
})
Vue.directive('longpress',
  function (el, binding, vNode) {
    if (binding.arg === binding.oldArg) {
      return
    }
    if (typeof binding.value !== 'function') {
      const compName = vNode.context.name
      let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`
      if (compName) { warn += `Found in component '${compName}' ` }
      console.warn(warn)
    }
    let pressTimer = null
    const start = (e) => {
      if (e.type === 'click' && e.button !== 0) {
        return
      }

      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          if (binding.args !== '') { handler(binding.arg) } else handler()
        }, 1000)
      }
    }
    const cancel = (e) => {
      if (pressTimer !== null) {
        clearTimeout(pressTimer)
        pressTimer = null
      }
    }
    const handler = (e) => {
      binding.value(e)
    }

    // Add Event listeners
    el.addEventListener('mousedown', start)
    el.addEventListener('touchstart', start)
    // Cancel timeouts if this events happen
    el.addEventListener('click', cancel)
    el.addEventListener('mouseout', cancel)
    el.addEventListener('touchend', cancel)
    el.addEventListener('touchcancel', cancel)
  }
)
