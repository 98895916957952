import enVuetify from 'vuetify/es5/locale/en'

const messages = {
  // These are the default vuetify translations
  ...enVuetify,
  // Here write your custom transalations or override the default vuetify ones

  language: 'Language',
  currency: 'Currency',
  all: 'All',
  actions: 'Actions',
  more: 'More',
  save: 'Save',
  item: 'Item | Items',
  receipt: 'Receipt | Receipts',
  offer: 'Offer | Offers',
  order: 'Order',
  yes: 'Yes',
  no: 'No',
  active: 'Active',
  taxName: 'VAT',
  reset: 'Reset',
  confirm: 'Confirm',
  languages: {
    en: 'English',
    hr: 'Croatian'
  },
  themes: {
    dark: 'Dark',
    light: 'Light'
  },
  appLanguageTxt: 'Language',
  theme: 'Theme',
  price: 'Price',
  writeInButtonLabel: 'Free entry of an item or service',
  registerSuccessMsg: 'New bussiness account has been created, please confirm you email!',

  passwordReset: {
    infoMessage: 'Enter your user account\'s verified email address and we will send you a password reset link.',
    resetBtn: 'Send password reset email'
  },

  formDetails: {
    firstName: 'First name',
    lastName: 'Last name',
    password: 'Password',
    confirmPassword: 'Confirm password',
    termsAgree: 'Agree to the terms of use',
    terms: 'Terms of use',
    name: 'Name',
    itemName: 'Item Name',
    address: 'Address',
    city: 'City',
    zipCode: 'Zip code',
    country: 'Country',
    oib: 'Oib',
    oibDuplicate: 'Oib exists, please enter unique Oib.',
    requiredField: 'Required field',
    numGreater: 'Value has to be greater than {target}',
    passwordMatchRule: 'passwordMatchRule',
    oibValid: 'Enter valid OIB',
    emailValid: 'Enter valid e-mail adress',
    numberValid: 'Enter correct number value',
    usernameValid: 'Enter valid username',
    logIn: 'Log in',
    register: 'Register',
    logOut: 'Log out',
    registerText: 'Register',
    forgotPassword: 'Forgot password?',
    phone: 'Phone',
    mobile: 'Mobile',
    additionalInfo: 'Additional info',
    associateCode: 'Associate code',
    leaveFormTitle: 'Are you sure you want to leave?',
    leaveFormText: 'Changes you made may not be saved.',
    leave: 'Leave',
    stay: 'Stay',
    downloadReport: 'Download report',
    associateType: 'Associate type',
    associateBuyer: 'Buyer',
    associateSupplier: 'Supplier',
    issueReceipt: 'Issue receipt',
    unit: 'Unit',
    baseAmount: 'Base Amount',
    writeInTitle: 'Insert Product or Service',
    supplierType: 'Supplier type',
    supplierCode: 'Supplier code',
    username: 'Username',
    saveAssociate: 'Save associate',
    changeReceiptType: 'Change receipt type'
  },

  companiesTabs: 'My companies',
  companies: {
    titleText: 'My companies',
    addBtn: 'Add company',
    types: {
      doo: 'd.o.o',
      jdoo: 'j.d.o.o',
      crafts: 'Crafts',
      pcrafts: 'Crafts'
    },
    taxType: 'Tax type',
    taxTypes: {
      profit: 'Profit tax',
      income: 'Income tax',
      none: 'None'
    },
    warehouseType: 'Warehouse type',
    warehouseTypes: {
      basic: 'Basic',
      module: 'Module'
    },
    defaultIban: 'Main IBAN',
    notePOS: 'Note POS',
    noteA4: 'Note A4',
    receiptLogo: 'Receipt logo',
    digitalSignatureImage: 'Digital signature image',
    csv: 'Upload CSV'
  },
  users: {
    titleText: 'Users',
    addUser: 'Add user',
    searchLabelText: 'Search by username'
  },
  userDataTabs: {
    user: 'Settings',
    bill: 'Bill',
    closeBalance: 'Close balance'
  },
  reports: {
    titleText: 'Reports',
    addUser: 'Generate report'
    // searchLabelText: 'Pretraga po korisničkom imenu'
  },
  uploadItems: {
    titleText: 'Item upload'
  },
  instructions: {
    titleText: 'Instructions'
  },
  warehouse: {
    titleText: 'Warehouse'
  },
  discount: {
    titleText: 'Discounts',
    rate: 'Rate discount',
    amount: 'Amount discount',
    manual: 'Manual insert'
  },
  restaurant: {
    menu: 'Menu'
  },
  printerConfig: {
    title: 'Printer configuration',
    installedPrinter: 'Installed Printer',
    installedPrinters: 'Installed Printers',
    networkPrinter: 'Network Printer',
    networkPrinters: 'Network Printers',
    btPrinter: 'Bluetooth Printer',
    btPrinters: 'Bluetooth Printers',
    addPrinter: 'Add printer',
    editPrinter: 'Edit printer',
    selectPrinter: 'Select printer',
    cancel: 'Cancel',
    printerName: 'Printer name',
    networkPrinterName: 'Network printer name',
    font: 'Font',
    wSize: 'Character width',
    hSize: 'Character height',
    size: 'Character size',
    width: 'Width (mm)',
    lineLength: 'Number of characters in a row',
    port: 'Network port'
  },
  posConfig: {
    title: 'Pos configuration'
  },
  cardConfig: {
    title: 'Card configuration'
  },
  inventura: {
    title: 'Inventory',
    active: 'Active inventory'
  },
  companyTabs: {
    myCompanis: 'My Companies',
    pointsOfSale: 'Points of sale',
    lager: 'Lager',
    categories: 'Item categories',
    receipts: 'Receipts and offers',
    associates: 'Associates',
    suppliers: 'Suppliers',
    users: 'Users',
    reports: 'Reports',
    posdReport: 'PO SD form',
    kprReport: 'Sales ledger',
    uploadItems: 'Upload items',
    instructions: 'Instructions',
    norm: 'Norm items',
    discounts: 'Discounts'
  },
  communicationConfig: {
    title: 'Communication'
  },
  company: {
    addPointOfSaleBtn: 'Add point of sale',
    addLagerItemBtn: 'Add item',
    addPrinterBtn: 'Add printer',
    addOrgUnitBtn: 'Add organization unit',
    addAssociate: 'Add associate',
    addSupplier: 'Add supplier',
    addSupplierIntegration: 'Add supplier integration',
    addIntegration: 'Add integration',
    addCategory: 'Add category',
    associate: 'Associate',
    suppliers: 'Suppliers',
    supplier: 'Supplier',
    editAssociate: 'Edit associate',
    editSupplier: 'Edit supplier',
    addSupplierItems: 'Add supplier items',
    supplierItems: 'Supplier items',
    addDiscountBtn: 'Add discount'

  },

  pointsOfSaleTabs: {
    myCompanis: 'My Companies',
    pointsOfSale: 'Points of sale',
    registers: 'Cash Registers',
    lager: 'Lager',
    categories: 'Item categories',
    warehouse: 'Warehouse',
    priceList: 'Price List',
    receipts: 'Receipts and offers',
    associates: 'Associates',
    posdReport: 'PO SD form',
    kprReport: 'Sales ledger',
    instructions: 'Instructions',
    warehouseItems: 'Items',
    warehouseDocuments: 'Documents',
    warehouseDocumentsOverview: 'Document overview',
    warehouseDocumentsList: 'Document list',
    norm: 'Norm items',
    discounts: 'Discounts',
    restaurant: 'Restaurant',
    menu: 'Menu',
    master: 'Master',
    inventory: 'Inventory'
  },
  pointOfSale: {
    addLagerItemBtn: 'Add item',
    addWarehouseItemBtn: 'Add item',
    addPricelistItemBtn: 'Add item',
    addCategory: 'Add category',
    addAssociateBtn: 'Add associate',
    addRegisterBtn: 'Add cash register',
    stock: 'Stock',
    searchItemsLabel: 'Search items',
    cashRegisterNoAddOptionText: 'You are not able to add a new cash register, try to add a certificate to the company and try again.',
    issueReceipt: 'Issue receipt'
  },
  cashRegisterTabs: {
    myCompanis: 'My Companies',
    pointsOfSale: 'Points of sale',
    registers: 'Cash Registers',
    lager: 'Lager',
    warehouse: 'Warehouse',
    priceList: 'Price List',
    receipts: 'Receipts and offers',
    posdReport: 'PO SD form',
    kprReport: 'Sales ledger',
    reports: 'Reports',
    instructions: 'Instructions',
    norm: 'Norm items',
    closeBalanceTitles: 'Close balance titles',
    logout: 'Logout from cash register'
  },
  cashRegister: {
    registerTypes: {
      mp: 'Retail cash register',
      vp: 'Wholesale cash register',
      all: 'All'
    },
    registerLoginTypes: {
      default: 'Login with user',
      selectUser: 'Login by selecting user',
      pincode: 'Login by entering user pincode'
    },
    afterReceiptBehaviours: {
      emptyBasket: 'Empty basket',
      showCashRegs: 'Show cash registers',
      showAllReceipts: 'Show all receipts'
    },
    noCategoryItems: 'No items for {category} category. Please add some items into the price list.',
    noCategories: 'There is no category. Please add some.',
    lastReceiptNumber: 'Last receipt number',
    lastOfferNumber: 'Last offer number',
    user: 'User ',
    otherUserReserved: ' logged in cash register.',
    selectTemplate: 'Select register template',
    search: 'Search Items',
    searchDescription: 'Search items by name or by code',
    receiptsOverview: 'Receipts Overview',
    selectProduct: 'Select Product',
    searchByReceiptNumber: 'Search by receipt number'
  },
  lager: {
    itemTypes: {
      goods: 'Material goods',
      service: 'Services'
    }
  },
  priceList: {
    dateFromGreater: 'Active to must be greater than active from!',
    noPrice: 'Please choose at least one price for the item'
  },
  receipts: {
    receiptNumber: 'Receipt number',
    offerNumber: 'Offer number',
    customer: 'Customer',
    receiptPrice: 'Receipt price',
    offerPrice: 'Offer price',
    receiptIssueDate: 'Issue date',
    receiptPaymentDate: 'Receipt payment date',
    receiptDeliveryDate: 'Delivery date',
    receiptPaymentDue: 'Payment due date',
    retailCustomer: 'Retail customer',
    receiptPaidStatus: 'Paid',
    status: 'Receipt status',
    PAID: 'Paid',
    UNPAID: 'Unpaid',
    PROCESSING: 'Processing',
    EMAIL_SENT: 'E-mail sent',
    OFFER_SENT: 'E-mail sent',
    WARNING_SENT: 'Warning sent',
    OFFER_CREATED: 'Offer created',
    VIRMAN_CREATED: 'Virman created',
    UNKNOWN_STATUS: 'Unknown status',
    fiscalizing: 'Fiscalize all receipts'
  },
  tables: {
    tables: 'Tables',
    create: 'Create New',
    close: 'Finish',
    select: 'Select Table',
    selected: 'Selected Table',
    saveTo: 'Save to',
    saveTable: 'Spremi',
    clear: 'Clear',
    new: 'New',
    save_title: 'Save Order',
    clearOrder: 'Would you like to delete order from the table',
    save: 'Would you like to save order to the table',
    delete: 'Would you like to delete',
    unlockTitle: 'Table Unlocking',
    unlockMessage: 'is locked. Are you sure you want to unlock it?',
    unlockMessageOther: 'is locked by another user. Are you sure you want to unlock it?',
    note: 'Warning',
    newTable: 'Save to a New Table',
    existingTable: 'Transfer to Existing Table',
    splitTablesDisclaimer: 'Click ">" to transfer single item or ">>" to transfer all items that you wish to be charges or transfered to another table.',
    splitTable: 'Split table',
    splitTableAll: 'Transfer all',
    transferTableTitle: 'Transfer Table',
    transferTableButton: 'Transfer Table',
    receiptNote: 'Receipt note',
    addReceiptNote: 'Add receipt note',
    selectedItems: 'Items',
    noteMessage: 'Unfinished order in cart. Would you like to continue without finishing?',
    continue: 'Continue',
    cancel: 'Cancel',
    cartRecpitulation: 'Table Recapitulation'
  },
  tooltips: {
    payWithCash: 'Use cash',
    payWithCards: 'Use card',
    payR1: 'R1 receipt, cash, card, virman',
    selectTable: 'Select table or create one',
    newOrder: 'Remove current selection and begin new order',
    clearTable: 'Empty selected table. If the table is marked for deletion, it will be deleted from database as well',
    saveTable: 'Save items in cart to the selected table',

    searchItems: 'Search items by name',
    repReceipt: 'Set receipt as entertainment expenses',
    receiptsDialog: 'Search receipts issued since the last close balance',
    closeBalance: 'Close balance',
    splitTable: 'Split table contents to sepatate receipts',

    wolt: 'Payment via Wolt',
    bolt: 'Payment via Bolt',
    glovo: 'Payment via Glovo',

    enableOsk: 'On-screen keyboard on/off'
  },
  errors: {
    errorTitle: 'Error',
    nullReceipt: 'No receipt data.',
    nullReportReceipts: 'Receipts does not exist in given date.',
    unknownRegister: 'There is no cash register where the receipt has been created or it is in the wrong status.',
    noCashRegisterData: 'Technical problem: Cash register unavailable.',
    noCorrespondingUser: 'There is no user record corresponding to this identifier. The user may have been deleted.',
    wrongPassword: 'The password is invalid or the user does not have a password.',
    tooManyRequests: 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',
    simpleSequence: 'Wrong password format: Must not be simple number sequence.',
    passwordContains: 'Wrong password format: Must not contain name, surname or e-mail address.',
    shortPassword: 'Wrong password format: Must be at least 8 characters long.',
    invalidDataUpload: 'Items upload failed.',
    invalidCashRegReservation: 'Invalid cash register reservation.',
    noLeadingSpaces: 'Leading spaces are not allowed.',
    noChangesInEntities: 'No changes have been made.',
    duplicateCompany: 'This OIB is already used for another item',
    duplicateItem: 'This item already exists',
    itemCodeDuplicate: 'This code is already used for another item.'
  },
  messages: {
    resetLinkSent: 'Check your email for password reset link'
  },
  process: 'Process',
  cancel: 'Cancel',
  backward: 'Back',
  createRegisterReportBtn: 'Create a report',
  finishPayment: 'Finish',
  paymentMethodText: 'Payment method',
  issueYear: 'Issue year',
  issueDate: 'Issue date',
  deliveryDate: 'Delivery date',
  paymentDueDate: 'Due date',
  offerDueDate: 'Offer Due date',
  fiscalizeText: 'Fiscalize',
  receiptTypeText: 'Receipt type',
  noLabelReceiptText: 'No label',
  amountReceivedText: 'Amount received',
  name: 'Name',
  quantity: 'Quantity',
  receiptTitle: 'Payment',
  referenceNumber: 'Reference number',
  receiptNumber: 'Receipt number',
  offerNumber: 'Offer number',
  change: 'Change',
  total: 'Total',
  totalWithDiscount: 'Total with discount',
  note: 'Note',
  journalNum: 'Journal entry number',
  noItemsSelected: 'No items selected',
  accountDetailsTitle: 'Account details',
  receiptTypes: {
    noCustomerData: 'Without customer data',
    withCustomerData: 'With customer data',
    offer: 'Offer'
  },
  paymentMethods: {
    cash: 'Cash',
    card: 'Card',
    transaction: 'Transaction',
    virman: 'Virman',
    representation: 'Representation',
    cashMethod: 'Cash',
    cardMethod: 'Cashless',
    offer: 'Offer'
  },
  receiptLanguages: {
    en: 'English',
    hr: 'Croatian'
  },
  notifications: {
    title: 'Notifications'
  },
  contractNumText: 'Contract number',
  filterLabel: 'Search',
  filterPlaceholderLabel: 'Start typing to search...',
  searchLabel: 'Press enter to search',
  filterOib: 'Account (OIB)',
  filterReceiptNumber: 'Receipt reference number',
  showAdditionalFilters: 'Show additional filters',
  hideAdditionalFilters: 'Hide additional filters',
  orders: {
    titleText: 'Orders',
    createOrder: 'Create order',
    showOrders: 'Show orders'
  }
}

export default {
  $vuetify: messages
}
