export default {
  path: '/companies',
  name: 'companies',
  component: () => import(/* webpackChunkName: "Business" */ '@/modules/companies/Companies'),
  meta: {
    title: '$vuetify.companies.titleText',
    addItemText: '$vuetify.companies.addBtn',
    addItemAction: 'addCompany',
    filterSearch: true,
    requiresAuth: true,
    sideNav: 'Companies',
    topBar: 'CompaniesTopBar'
  }
}
