import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import themes from './theme'
import i18n from '@/plugins/i18n'

Vue.use(Vuetify)

const hostname = window.location.hostname

const selectedTheme = themes[hostname] || themes.default

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: selectedTheme
  },
  lang: {
    current: i18n.locale,
    t: (key, ...params) => i18n.t(key, params)
  }
})
