
import { auth, df } from '@/plugins/firebase'
import state from '@/state'
export default {
  path: '/companies/:companyId/locations/:locationId/registers/:registerId',
  name: 'cashRegister',
  meta: {
    addItemText: '$vuetify.createRegisterReportBtn',
    actions: ['createReport', 'closeBalance'],
    requiresAuth: true,
    sideNav: 'CashRegister',
    topBar: 'AbstractRegisterTopBar'
  },
  beforeEnter: async (to, from, next) => {
    if (to.params.companyId === state.getCurrentCompany().id && to.params.locationId === state.getPointOfSale().id && to.params.registerId === state.getCashRegister().id) {
      next()
    } else {
      const companies = await df.doc(`user_companies/${auth.currentUser.uid}`).get()
      if (
        !companies ||
        !companies.exists ||
        !companies.data() ||
        Object.keys(companies.data().companies).length < 1 ||
        !companies.data().companies[to.params.companyId] ||
        companies.data().companies[to.params.companyId].status !== 'OK'
      ) {
        next({ name: 'catchall' })
        return
      }
      const company = companies.data().companies[to.params.companyId]
      const pointsOfSale = await df.doc(`user_locations/${auth.currentUser.uid}.${company.id}`).get()
      if (
        !pointsOfSale ||
        !pointsOfSale.data() ||
        !pointsOfSale.data().locations ||
        Object.keys(pointsOfSale.data().locations).length < 1 ||
        !pointsOfSale.data().locations[to.params.locationId] ||
        pointsOfSale.data().locations[to.params.locationId].status !== 'OK'
      ) {
        next({ name: 'catchall' })
        return
      }

      const pointOfSale = pointsOfSale.data().locations[to.params.locationId]
      const registers = await df.doc(`user_cash_registers/${auth.currentUser.uid}.${to.params.companyId}.${to.params.locationId}`).get()
      if (
        !registers ||
        !registers.data() ||
        !registers.data().cash_registers ||
        Object.keys(registers.data().cash_registers).length < 1 ||
        !registers.data().cash_registers[to.params.registerId] ||
        registers.data().cash_registers[to.params.registerId].status !== 'OK'
      ) {
        next({ name: 'catchall' })
        return
      }
      const register = registers.data().cash_registers[to.params.registerId]

      state.setCurrentCompany(company)
      state.setAppTitle(company.name)
      state.setPointOfSale(pointOfSale)
      state.setCashRegister(register)
      next()
    }
  },
  // component: () => import(/* webpackChunkName: "login" */ getCashRegister())
  // component: () => import(/* webpackChunkName: "login" */ 'sprintf("I pity the %s", hello)')

  component: () => import(/* webpackChunkName: "login" */ '@/modules/cash-register/AbstractRegister')
}
