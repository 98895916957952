<template>
  <v-dialog
    v-model="visible"
    persistent
    width="350"
    height="689"
    content-class="my-custom-dialog"
  >
    <v-overlay v-if="loading" absolute style="z-index: 1000">
      <v-progress-circular
        indeterminate
        size="64"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-form ref="registerForm" @submit.prevent="submit">
      <v-card :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
        <v-card-title
          style="background-color: #369fc9; font-weight: bold; color: white"
        >
          <span class="text-h5" style="font-weight: bold"
            >Registracija novog korisnika</span
          >
        </v-card-title>
        <v-card-text class="pt-10">
          <v-row>
            <v-col class="pb-0 mb-0 pt-0 mt-0">
              <v-text-field
                outlined
                dense
                :label="$t('$vuetify.formDetails.firstName')"
                prepend-inner-icon="mdi-account"
                class="rounded-card"
                :rules="[rules.req]"
                v-model="name"
                autofocus
              >
              </v-text-field>
            </v-col>
            <v-col class="pb-0 mb-0 pt-0 mt-0">
              <v-text-field
                :label="$t('$vuetify.formDetails.lastName')"
                prepend-inner-icon="mdi-account"
                outlined
                dense
                class="rounded-card"
                :rules="[rules.req]"
                v-model="lastName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 mb-0 pt-0 mt-0">
              <v-text-field
                label="Email"
                prepend-inner-icon="mdi-email"
                outlined
                dense
                :rules="[rules.req, rules.email]"
                v-model="email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 mb-0 pt-0 mt-0">
              <v-text-field
                outlined
                :label="$t('$vuetify.formDetails.password')"
                prepend-inner-icon="mdi-lock"
                v-model="password"
                :rules="[rules.req]"
                :type="passVisible ? 'text' : 'password'"
                :append-icon="passVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (passVisible = !passVisible)"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 mb-0 pt-0 mt-0">
              <v-text-field
                outlined
                :label="$t('$vuetify.formDetails.confirmPassword')"
                prepend-inner-icon="mdi-lock"
                v-model="confirmPassword"
                dense
                :rules="[
                  rules.eq(
                    password,
                    `${$t('$vuetify.formDetails.passwordMatchRule')}`
                  ),
                  rules.req,
                ]"
                :type="confirmPassVisible ? 'text' : 'password'"
                :append-icon="confirmPassVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (confirmPassVisible = !confirmPassVisible)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 mb-0 pt-0 mt-0">
              <v-text-field
                label="Broj telefona"
                prepend-inner-icon="mdi-cellphone-information"
                outlined
                hide-details
                dense
                :rules="[rules.req]"
                v-model="phoneNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0 pb-0 mb-0 pt-0 mt-0">
              <a
                href="https://www.aikasa.app/dokumenti/uvjeti-koristenja"
                target="_blank"
                >{{ $t("$vuetify.formDetails.terms") }}</a
              >
            </v-col>
          </v-row>
          <v-row class="pb-0">
            <v-col class="pb-0 mb-0 pt-0 mt-0">
              <v-checkbox
                v-model="terms"
                color="primary"
                :rules="[rules.req]"
                hide-details
                dense
                :label="$t('$vuetify.formDetails.termsAgree')"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-5 pb-6 pl-10">
          <v-spacer></v-spacer>
          <v-btn class="btn dangerButton" @click="close"> Odustani </v-btn>
          <v-btn
            id="success"
            class="btn successButton"
            :loading="submitting"
            type="submit"
          >
            {{ $t("$vuetify.formDetails.register") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { registerUser } from '@/api/default'
export default {
  name: 'Register',
  data: () => ({
    visible: false,
    loading: false,
    passVisible: false,
    confirmPassVisible: false,
    name: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    terms: false,
    submitting: false,
    error: {
      show: false,
      message: undefined
    },
    rules: {
      req: rules.req(),
      eq: rules.eq,
      email: rules.email()
    }
  }),
  inject: ['showMsgBox'],
  methods: {
    open () {
      this.visible = true
    },
    close () {
      this.visible = false
      this.$refs.registerForm.reset()
    },
    async submit () {
      if (!this.$refs.registerForm.validate()) return
      this.loading = true
      try {
        const owner = {
          email: this.email,
          name: this.name.trim(),
          surname: this.lastName.trim(),
          password: this.password,
          password_confirmation: this.confirmPassword,
          agree_terms_of_use: this.terms ? 'YES' : 'NO',
          phone_number: this.phoneNumber
        }
        const resp = await registerUser({
          action: {
            operation: 'set',
            entity: 'owner',
            params: {
              owner: owner
            }
          }
        })
        if (resp.data && resp.data.header) {
          if (resp.data.header.code !== 200) {
            this.error.show = true
            this.error.message = resp.data.header.error
            this.showMsgBox({
              text: resp.data.header.error,
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
          } else {
            this.showMsgBox({
              text: this.$t('$vuetify.registerSuccessMsg'),
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'info'
            })
            this.close()
          }
        }
      } catch (error) {
        this.error.show = true
        this.error.message = error
      } finally {
        this.loading = false
      }
    },
    dismissError () {
      this.error.show = false
      this.error.message = undefined
    }
  }
}
</script>
