class LocalStore {
  getItem (key) {
    if (localStorage.getItem(key) && localStorage.getItem(key) !== null && localStorage.getItem(key) !== 'undefined') {
      return JSON.parse(localStorage.getItem(key))
    }

    return null
  }

  setItem (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  removeItem (key) {
    localStorage.removeItem(key)
  }
}

const localstore = new LocalStore()

export default localstore
