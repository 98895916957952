<template>
  <v-dialog
    v-model="visible"
    persistent
    style="position: absolute; z-index: 7"
    :width="opts.width"
    :max-width="opts.maxWidth"
    :min-height="opts.minHeight"
    retain-focus
  >
    <v-card>
      <v-toolbar dark color="#F57C02" flat height="40">
        <v-toolbar-title
          class="white--text"
          style="font-weight: bold"
          v-if="opts.title"
          >{{ opts.title }}</v-toolbar-title
        >
      </v-toolbar>

      <v-card-subtitle>
        {{ opts.subtitle }}
      </v-card-subtitle>

      <v-card-text style="font-weight: bold; color: black">
        {{ opts.text ? opts.text : "" }}

        <slot></slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="opts.actions && opts.actions.includes('cancel')"
          @click="onCancelClick"
          class="btn dangerButton"
          color="#757575"
        >
          {{ opts.cancelBtnText }}
        </v-btn>
        <v-btn
          v-if="opts.actions && opts.actions.includes('ok')"
          @click="onOkClick"
          id="success"
          class="btn successButton"
          :loading="loading"
          color="#757575"
          >{{ opts.okBtnText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const defaults = {
  title: undefined,
  type: 'notification',
  icon: 'mdi-notification',
  text: undefined,
  actions: ['ok'],
  okBtnText: 'Ok',
  submitting: false,
  cancelBtnText: 'Cancel',
  color: 'info',
  okBtnColor: 'okButton',
  cancelBtnColor: 'okButton',
  subtitle: '',
  width: '450',
  minHeight: '125',
  maxWidth: '1000'
}
export default {
  name: 'MsgBox',
  data: () => ({
    opts: {},
    visible: false
  }),
  props: ['loading', 'preventClose', 'toolbar'],
  methods: {
    show (config = {}) {
      this.opts = { ...defaults, ...config }
      this.visible = true
      this.$emit('show')
    },
    hide () {
      this.visible = false
    },
    onOkClick () {
      this.opts = {}
      this.$emit('ok')
      this.visible = false
    },
    onCancelClick () {
      this.opts = {}
      this.visible = false
      this.$emit('cancel')
    }
  }
}
</script>
