<template>
  <v-dialog
    :scrollable="options.scrollable"
    :persistent="options.persistent"
    v-model="dialog"
    :max-width="options.width"
    @keyup.esc.stop="cancel"
    @keydown.enter="agree()"
    style="z-index: 7"
    tabindex="1"
  >
    <v-card class="pb-1">
      <v-toolbar v-if="toolbar" dark color="#1577da" flat height="40">
        <v-toolbar-title class="white--text" style="font-weight: bold">{{
          title
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text
        class="pt-2"
        :style="{
          'white-space': options.whiteSpace,
          'font-weight': 'bold',
          color: 'black',
        }"
        v-html="message || ''"
      >
      </v-card-text>
      <slot></slot>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn
          v-if="options.confirmVisible"
          :disabled="confirmDisabled"
          id="success"
          class="btn successButton"
          ref="confirmRef"
          @keyup.enter="agree"
          @click.stop="agree"
          >{{ options.confirmText }}</v-btn
        >
        <v-btn
          v-if="!options.hideCancel"
          class="btn dangerButton"
          outlined
          @click.stop="cancel"
          >{{ options.cancelText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

const defaults = {
  color: 'primary',
  width: 290,
  zIndex: 200,
  persistent: true,
  confirmVisible: true,
  confirmText: 'Ok',
  cancelText: 'Cancel',
  scrollable: false,
  maxHeight: 900,
  whiteSpace: true
}

export default {
  props: ['toolbar', 'confirmDisabled'],
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: defaults,
    confirmDis: true
  }),
  methods: {
    open (title, message, options) {
      this.$nextTick(() => {
        this.dialog = true
        this.title = title
        this.message = message
      })

      this.options = { ...defaults, ...options }
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree () {
      this.$emit('selection', true)
      this.resolve(true)
    },
    cancel (e) {
      this.options = defaults
      this.resolve(false)
      this.$emit('cancel')
      e.stopPropagation()
      this.dialog = false
    },
    close () {
      this.dialog = false
      this.$emit('cancel')
    },
    handleKeyEvents (e) {
      e = e || window.event

      switch (e.keyCode) {
        case 13: { // enter
          this.agree()
        }
      }
    }
  }
}
</script>
