<template >
  <v-alert
    style="position: absolute; right: 20px; bottom: 0"
    v-model="visible"
    :type="opts.type"
    :color="opts.type"
    text
    prominent
    dismissible
    @input="dismiss"
    >{{ opts.text }}</v-alert
  >
</template>
<script>
const defaults = {
  type: 'info'
}
export default {
  name: 'Alert',
  data: () => ({
    opts: {},
    visible: false
  }),
  methods: {
    show (config = {}) {
      this.opts = { ...defaults, ...config }
      this.visible = true
      this.$emit('show')
    },
    dismiss () {
      this.visible = false
    }
  }
}
</script>
