import axios from 'axios'

function configure () {
  axios.defaults.baseURL = process.env.VUE_APP_API_ROOT
}
// function get(url){
//     return axios.get(url)
// }

function post (path, payload) {
  return axios.post(path, JSON.stringify(payload))
}

function registerUser (payload = {}) {
  configure()
  const path = '/companyowner'
  if (payload.action.params.owner === undefined) {
    return Promise.reject(new Error('Missing required  parameter: owner'))
  }
  return post(path, payload)
}

function generatePosd (payload = {}) {
  configure()
  const path = '/posd'
  return post(path, payload)
}

export { registerUser, generatePosd }
