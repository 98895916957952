import Vue from 'vue'

Vue.filter('capitalize', (value, suffix = '') => {
  if (!value) {
    return value
  }

  if (value === undefined || value === null) {
    return value
  }

  const str = value.toLowerCase().split(' ')

  if (str.length === 0) {
    return ''
  }

  return value.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
})

Vue.filter('capitalize-sentence', (value) => {
  if (!value) {
    return value
  }
  const str = value.toLowerCase().split(' ')
  if (str.length === 0) {
    return ''
  }
  return value[0].toUpperCase() + value.slice(1)
})

Vue.filter('money', (value, devider = null, currency = null) => {
  let formatter
  switch (currency) {
    case 'EUR': {
      formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      })
      break
    }
    case 'USD': {
      formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      })
      break
    }
    case 'GBP': {
      formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP'
      })
      break
    }
    default: {
      formatter = new Intl.NumberFormat('hr', {
        style: 'currency',
        currency: 'HRK'
      })
    }
  }
  if (devider) {
    return formatter.format(value / devider)
  } else {
    return formatter.format(value)
  }
})

Vue.filter('yesno', (value) => {
  if (value) {
    return 'DA'
  } else {
    return 'NE'
  }
})

Vue.filter('local', (value, includetime = true) => {
  if (value) {
    const d = new Date(value * 1000)// Convert the passed timestamp to milliseconds
    const yyyy = d.getFullYear()
    const mm = ('0' + (d.getMonth() + 1)).slice(-2)// Months are zero based. Add leading 0.
    const dd = ('0' + d.getDate()).slice(-2)// Add leading 0.
    const hh = d.getHours()
    const h = hh
    const min = ('0' + d.getMinutes()).slice(-2)
    const time = includetime === true ? `${dd}.${mm}.${yyyy}. ${h}:${min}` : `${dd}.${mm}.${yyyy}.`
    return time
  }
})

Vue.filter('localWithoutDot', (value, includetime = true) => {
  if (value) {
    const d = new Date(value * 1000)// Convert the passed timestamp to milliseconds
    const yyyy = d.getFullYear()
    const mm = ('0' + (d.getMonth() + 1)).slice(-2)// Months are zero based. Add leading 0.
    const dd = ('0' + d.getDate()).slice(-2)// Add leading 0.
    const hh = d.getHours()
    let h = hh
    if (h < 10) {
      h = '0' + h
    }
    const min = ('0' + d.getMinutes()).slice(-2)
    const time = includetime === true ? `${dd}.${mm}.${yyyy} ${h}.${min}` : `${dd}.${mm}.${yyyy}`
    return time
  }
})
