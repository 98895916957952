
import { DateTime } from 'luxon'

function clone (obj) {
  return JSON.parse(JSON.stringify(obj))
}

function duck (obj1, obj2) {
  // console.log(JSON.stringify(obj1))
  // console.log(JSON.stringify(obj2))
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

function toISOLocal (d) {
  var z = n => ('0' + n).slice(-2)
  var zz = n => ('00' + n).slice(-3)
  var off = d.getTimezoneOffset()
  var sign = off < 0 ? '+' : '-'
  off = Math.abs(off)

  return d.getFullYear() + '-' +
    z(d.getMonth() + 1) + '-' +
    z(d.getDate()) + 'T' +
    z(d.getHours()) + ':' +
    z(d.getMinutes()) + ':' +
    z(d.getSeconds()) + '.' +
    zz(d.getMilliseconds()) +
    sign + z(off / 60 | 0) + ':' + z(off % 60)
}

function getWidth () {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  )
}

function getHeight () {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  )
}

function isNumeric (value) {
  return value.match(/^[0-9]+$/) !== null
}

function roundUp (number, multiplier = false) {
  const bigDecimal = require('js-big-decimal')
  if (multiplier) {
    return parseFloat((bigDecimal.round(number / 100, 2, bigDecimal.RoundingModes.HALF_UP)) * 100)
  } else {
    return parseFloat((bigDecimal.round(number, 2, bigDecimal.RoundingModes.HALF_UP)))
  }
}

const formatDateTimeForQR = (date) => {
  const currentDate = DateTime.fromJSDate(date)
  const month = currentDate.month < 10 ? '0' + currentDate.month : currentDate.month
  const day = currentDate.day < 10 ? '0' + currentDate.day : currentDate.day
  const hour = currentDate.hour < 10 ? '0' + currentDate.hour : currentDate.hour
  const minute = currentDate.minute < 10 ? '0' + currentDate.minute : currentDate.minute
  return `${currentDate.year}${month}${day}_${hour}${minute}`
}

const formatDoubleDigitDate = (date, labeled) => {
  const currentDate = DateTime.fromJSDate(date)
  const month = currentDate.month < 10 ? '0' + currentDate.month : currentDate.month
  const day = currentDate.day < 10 ? '0' + currentDate.day : currentDate.day
  if (labeled) {
    return `Datum: ${day}.${month}.${currentDate.year}`
  } else {
    return `${day}.${month}.${currentDate.year}`
  }
}

const formatDoubleDigitTime = (date, labeled) => {
  const currentDate = DateTime.fromJSDate(date)
  const hour = currentDate.hour < 10 ? '0' + currentDate.hour : currentDate.hour
  const minute = currentDate.minute < 10 ? '0' + currentDate.minute : currentDate.minute
  if (labeled) {
    return `Vrijeme: ${hour}:${minute}`
  } else {
    return `${hour}:${minute}`
  }
}

const currencyToFloat = (currency) => {
  const curRe = /\D*(\d+|\d.*?\d)(?:\D+(\d{2}))?\D*$/
  const parts = curRe.exec(currency)
  const number = parseFloat(parts[1].replace(/\D/g, '') + '.' + (parts[2] ? parts[2] : '00'))
  return parseFloat(number.toFixed(2))
}

// milliseconds constants
const SEC = 1000
const MIN = SEC * 60
const HOUR = MIN * 60
const DAY = HOUR * 24

export {
  clone,
  duck,
  toISOLocal,
  getWidth,
  getHeight,
  isNumeric,
  formatDoubleDigitDate,
  formatDoubleDigitTime,
  formatDateTimeForQR,
  currencyToFloat,
  SEC,
  MIN,
  HOUR,
  DAY,
  roundUp
}
