import Vue from 'vue'

import localstore from '@/localstore.js'
const state = Vue.observable({
  drawer: false,
  setDrawer: (drawer) => {
    state.drawer = drawer
  },
  filter: '',
  search: '',
  r_time: 0,
  events: {
    addCompany: false,
    addUser: false,
    openAddPointOfSaleForm: false,
    openAddRegisterForm: false,
    openaddItemForm: false,
    openAddWarehouseItemForm: false,
    openAddWarehouseModuleItemForm: false,
    openAddPricelistItemForm: false,
    openAddMenuItem: false,
    openAddCategoryForm: false,
    openaddDiscount: false,
    addAssociate: false,
    createReport: false,
    closeBalance: false,
    openAddPrinter: false,
    openAddOrgUnit: false,
    addSupplier: false,
    addIntegration: false,
    openAddPos: false,
    openAddCard: false,
    openAddCommunication: false
  },

  isAuthenticated: false,
  title: undefined,
  getAppTitle: () => {
    return state.title || localstore.getItem('title') || ''
  },
  setAppTitle: (title) => {
    localstore.setItem('title', title)
    state.title = title
  },
  removeAppTitle: () => {
    if (state.title) {
      state.title = undefined
    }
    if (localstore.getItem('title')) {
      localstore.removeItem('title', localstore.getItem('title'))
    }
  },

  printer: 'A4',
  getPrinter: () => {
    return localstore.getItem('printer') || state.printer || {}
  },
  setPrinter: (printer) => {
    localstore.setItem('printer', printer)
    state.printer = printer
  },

  company: undefined,
  getCurrentCompany: () => {
    return state.company || localstore.getItem('company') || {}
  },
  setCurrentCompany: (company) => {
    localstore.setItem('company', company)
    state.company = company
  },
  removeCurrentCompany: () => {
    if (state.company) {
      state.company = undefined
    }
    if (localstore.getItem('company')) {
      localstore.removeItem('company', localstore.getItem('company'))
    }
  },

  pointOfSale: undefined,
  getPointOfSale: () => {
    return state.pointOfSale || localstore.getItem('pointOfSale') || {}
  },
  setPointOfSale: (pointOfSale) => {
    localstore.setItem('pointOfSale', pointOfSale)
    state.pointOfSale = pointOfSale
  },
  removePointOfSale: () => {
    if (state.pointOfSale) {
      state.pointOfSale = undefined
    }
    if (localstore.getItem('pointOfSale')) {
      localstore.removeItem('pointOfSale', localstore.getItem('pointOfSale'))
    }
  },

  cashRegister: undefined,
  getCashRegister: () => {
    return state.cashRegister || localstore.getItem('cashRegister') || {}
  },
  setCashRegister: (cashRegister) => {
    localstore.setItem('cashRegister', cashRegister)
    state.cashRegister = cashRegister
  },
  removeCashRegister: () => {
    if (state.cashRegister) {
      state.cashRegister = undefined
    }
    if (localstore.getItem('cashRegister')) {
      localstore.removeItem('cashRegister', localstore.getItem('cashRegister'))
    }
  },

  tables: [],
  getTables: () => {
    return state.tables || localstore.getItem('tables') || []
  },
  setTables: (tables) => {
    localstore.setItem('tables', tables)
    state.tables = tables
  },

  user: undefined,
  getUser: () => {
    return state.user || localstore.getItem('user') || {}
  },
  setUser: (user) => {
    localstore.setItem('user', user)
    state.user = user
  },
  removeUser: () => {
    state.user = {}
    state.cashRegisterUser = {}
    localstore.setItem('user', {})
    localstore.setItem('cashRegisterUser', {})
  },

  cashRegisterUser: undefined,
  getCashRegisterUser: () => {
    return state.cashRegisterUser || localstore.getItem('cashRegisterUser') || {}
  },
  setCashRegisterUser: (user) => {
    localstore.setItem('cashRegisterUser', user)
    state.cashRegisterUser = user
  },

  listView: true,
  isListView: () => {
    return state.listView || localstore.getItem('listView')
  },
  setListView: (view) => {
    localstore.setItem('listView', view)
    state.listView = view
  },

  locale: 'hr',
  getLocale: () => {
    return localstore.getItem('locale') || state.locale
  },
  setLocale: (locale) => {
    localstore.setItem('locale', locale)
    state.locale = locale
  },

  dark: 'false',
  isDark: () => {
    return localstore.getItem('dark') || state.dark
  },
  setDark: (val) => {
    localstore.setItem('dark', val.toString())
    state.dark = val.toString()
  },

  registerType: 'cash',
  resetRegisterType: () => {
    localstore.setItem('register_type', state.registerType)
  },
  getRegisterType: () => { return localstore.getItem('register_type') || state.registerType },
  setRegisterType: (registerType) => {
    localstore.setItem('register_type', registerType)
    state.registerType = registerType
  },

  registerPaymentPanel: 'default',
  resetRegisterPaymentPanel: () => {
    localstore.setItem('register_payment_panel', state.registerPaymentPanel)
  },
  getRegisterPaymentPanel: () => { return localstore.getItem('register_payment_panel') || state.registerPaymentPanel },
  setRegisterPaymentPanel: (registerPaymentPanel) => {
    localstore.setItem('register_payment_panel', registerPaymentPanel)
    state.registerPaymentPanel = registerPaymentPanel
  },

  panelRows: 6,
  getPanelRows: () => {
    return localstore.getItem('panelRows') || state.panelRows
  },
  setPanelRows: (value) => {
    localstore.setItem('panelRows', value)
    state.panelRows = value
  },

  panelCols: 6,
  getPanelCols: () => {
    return localstore.getItem('panelCols') || state.panelCols
  },
  setPanelCols: (value) => {
    localstore.setItem('panelCols', value)
    state.panelCols = value
  },

  panelColspan: 2,
  getPanelColspan: () => {
    return localstore.getItem('panelColspan') || state.panelColspan
  },
  setPanelColspan: (value) => {
    localstore.setItem('panelColspan', value)
    state.panelColspan = value
  },

  density: 'normal',
  getDensity: () => {
    return localstore.getItem('density') || state.density
  },
  setDensity: (density) => {
    localstore.setItem('density', density)
    state.density = density
  },

  osk: false,
  resetOsk: () => {
    localstore.setItem('osk', state.osk)
  },
  getOsk: () => {
    return localstore.getItem('osk') || state.osk
  },
  setOsk: (value) => {
    localstore.setItem('osk', value)
    state.osk = value
  },
  drawerOne: false,
  getDrawerOne: () => {
    return localstore.getItem('drawerOne') || state.drawerOne
  },
  setDrawerOne: (setDrawerOne) => {
    localstore.setItem('drawerOne', setDrawerOne)
    state.drawerOne = setDrawerOne
  },

  margin: '56px',
  getMargin: () => {
    return state.margin

    // return !state.drawerOne ? '0px' : '56px'
  },
  setMargin: (setMargin) => {
    // const margin = setMargin ? '56px' : '0'
    const margin = !state.getDrawerOne() ? '0px' : '56px'

    localstore.setItem('margin', margin)
    state.margin = margin
  },

  setPosPrinterSettings: (font) => {
    const printerSettings = { font: font }
    switch (font) {
      case 0:
        printerSettings.lineLength = 42
        break
      case 2:
        printerSettings.lineLength = 56
        break
      case 'A':
        printerSettings.lineLength = 48
        break
      case 'B':
        printerSettings.lineLength = 64
        break
      case 'C':
        printerSettings.lineLength = 64
        break
    }

    localstore.setItem('printerSettings', printerSettings)
    state.printerSettings = printerSettings
  },

  getPosPrinterSettings: () => {
    return localstore.getItem('printerSettings') || state.printerSettings
  },

  // JsPrintManager - font
  font: 'A',
  getFont: () => {
    return localstore.getItem('font') || state.font
  },
  setFont: (value) => {
    localstore.setItem('font', value)
    state.font = value
  },

  // JsPrintManager - font size
  fontSize: '0, 0',
  getFontSize: () => {
    return localstore.getItem('fontSize') || state.fontSize
  },
  setFontSize: (value) => {
    localstore.setItem('fontSize', value)
    state.fontSize = value
  },

  // JsPrintManager - font style
  fontStyle: '0, 0',
  getFontStyle: () => {
    return localstore.getItem('fontStyle') || state.fontStyle
  },
  setFontStyle: (value) => {
    localstore.setItem('fontStyle', value)
    state.fontStyle = value
  },

  // JsPrintManager - line length
  lineLength: 48,
  getLineLength: () => {
    return localstore.getItem('lineLength') || state.lineLength
  },
  setLineLength: (value) => {
    localstore.setItem('lineLength', value)
    state.lineLength = value
  },

  printerLib: 'BIXOLON80',
  getPrinterLib: () => {
    return localstore.getItem('printerLib') || state.printerLib
  },
  setPrinterLib: (value) => {
    localstore.setItem('printerLib', value)
    state.printerLib = value
  },

  printerData: {},
  getPrinterData: () => {
    return localstore.getItem('printerData') || state.printerData
  },
  setPrinterData: (value) => {
    localstore.setItem('printerData', value)
    state.printerData = value
  },

  selectedPrinter: null,
  getSelectedPrinter: () => {
    let res = localstore.getItem('selectedPrinter') || state.selectedPrinter
    if (!res || res === '') {
      res = {
        id: 'A4',
        library: 'NONE',
        name: 'Klasični printer (A4)',
        printer_name: 'A4',
        width: 'A4'
      }
    }
    return res
  },
  getJsPrintManagerLicenceUrl () {
    return 'https://www.neodynamic.com/licenses/jspm/v5/aduro-ideja'
  },
  setSelectedPrinter: (value) => {
    localstore.setItem('selectedPrinter', value)
    state.selectedPrinter = value
  },

  installedPrinters: [],
  getInstalledPrinters: () => {
    return localstore.getItem('installedPrinters') || state.installedPrinters
  },
  setInstalledPrinters: (value) => {
    localstore.setItem('installedPrinters', value)
    state.installedPrinters = value
  },

  tooltipDelay: 500,
  getTooltipDelay: () => {
    return localstore.getItem('tooltipDelay') || state.tooltipDelay
  },
  setTooltipDelay: (value) => {
    localstore.setItem('tooltipDelay', value)
    state.tooltipDelay = value
  },

  registerIds: [],

  version: 2,
  getVersion: () => {
    return state.version || localstore.getItem('version') || 1
  },
  setVersion: (version) => {
    localstore.setItem('version', version)
    state.version = version
  },

  subversion: 46,
  getSubversion: () => {
    return state.subversion || localstore.getItem('subversion') || 0
  },
  setSubversion: (subversion) => {
    localstore.setItem('subversion', subversion)
    state.subversion = subversion
  },

  templateConfig: {},
  getTemplateConfig: () => {
    return localstore.getItem('templateConfig') || state.templateConfig
  },
  setTemplateConfig: (templateConfig) => {
    localstore.setItem('templateConfig', templateConfig)
    state.templateConfig = templateConfig
  },

  searchType: true,
  getSearchType: () => {
    return localstore.getItem('searchType') || state.searchType
  },
  setSearchType: (searchType) => {
    localstore.setItem('searchType', searchType)
    state.searchType = searchType
  },

  orderPrintLocation: '',
  getOrderPrintLocation: () => {
    return localstore.getItem('orderPrintLocation') || state.orderPrintLocation
  },
  setOrderPrintLocation: (orderPrintLocation) => {
    localstore.setItem('orderPrintLocation', orderPrintLocation)
    state.orderPrintLocation = orderPrintLocation
  }

})

export default state
