module.exports = {
  normalAduro: {
    _meta: {
      tab: { key: '\t', text: 'Tab', width: 60, classes: 'control' },
      shiftl: { keySet: 'shifted', text: 'Shift', width: 100, classes: 'control' },
      shiftr: { keySet: 'shifted', text: 'Shift', width: 100, classes: 'control' },
      caps: { keySet: 'capsed', text: 'Caps lock', width: 80, classes: 'control' },
      space: { key: ' ', text: 'Space', width: 180 },
      enter: { key: '\r\n', text: 'Enter', width: 80, classes: 'control' },
      backspace: { func: 'backspace', classes: 'control backspace', width: 65 },
      accept: { func: 'accept', text: '⏎', classes: 'control featured' },
      next: { func: 'next', text: '➔', classes: 'control featured' }
    },

    default: [
      '@ 1 2 3 4 5 6 7 8 9 0 / + {backspace}',
      '{tab} q w e r t y u i o p š đ ž',
      '{caps} a s d f g h j k l č ć {caps}',
      '{shiftl} z x c v b n m , . - {shiftr}',
      '{next} {space} {accept}'
    ],
    shifted: [
      '~ ! " # $ % & \' ( ) = ? * {backspace}',
      '{tab} Q W E R T Y U I O P Š Đ Ž',
      '{caps} A S D F G H J K L Č Ć {caps}',
      '{shiftl} Z X C V B N M ; : _ {shiftr}',
      '{next} {space} {accept}'
    ],

    capsed: [
      '~ 1 2 3 4 5 6 7 8 9 0 ? * {backspace}',
      '{tab} Q W E R T Y U I O P Š Đ Ž',
      '{caps} A S D F G H J K L Č Ć {caps}',
      '{shiftl} Z X C V B N M ; : _ {shiftr}',
      '{next} {space} {accept}'
    ]
  },

  compactAduro: {
    _meta: {
      default: { keySet: 'default', text: 'abc', classes: 'control' },
      alpha: { keySet: 'default', text: 'Abc', classes: 'control' },
      shift: { keySet: 'shifted', text: 'ABC', classes: 'control' },
      numbers: { keySet: 'numbers', text: '123', classes: 'control' },
      space: { key: ' ', text: 'Space', width: 200 },
      backspace: { func: 'backspace', classes: 'control' },
      accept: { func: 'accept', text: '⏎', classes: 'control featured' },
      next: { func: 'next', text: '➔', classes: 'featured' },
      zero: { key: '0', width: 130 }
    },

    default: [
      'q w e r t y u i o p',
      ' a s d f g h j k l ',
      '{shift} z x c v b n m {backspace}',
      '{numbers} , {space} . {next} {accept}'
    ],

    shifted: [
      'Q W E R T Y U I O P',
      ' A S D F G H J K L ',
      '{default} Z X C V B N M ',
      '{numbers} _ {space} {backspace} {next} {accept}'
    ],

    numbers: [
      '1 2 3',
      '4 5 6',
      '7 8 9',
      '  {alpha} . {zero} {backspace} {next} {accept}'
    ]
  },

  numericAduro: {
    _meta: {
      backspace: { func: 'backspace', classes: 'control' },
      accept: { func: 'accept', text: '⏎', classes: 'control featured' },
      next: { func: 'next', text: '➔', classes: 'control featured' },
      zero: { key: '0', width: 130 }
    },

    default: [
      '1 2 3',
      '4 5 6',
      '7 8 9',
      '_ - . {zero} {backspace} {next} {accept}'
    ]
  }
}
