<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="visible = false"
    width="1200"
  >
    <v-card
      class="overflow-auto"
      :class="{'mobile-card': $vuetify.breakpoint.smAndDown}"
    >
      <v-card-title
        class="d-flex"
        style="vertical-align:middle"
      >
        <v-row class="pa-3 mb-2">
          <v-spacer></v-spacer>
          <v-icon
            large
            @click="close"
          >mdi-close</v-icon>
        </v-row>
      </v-card-title>
      <v-card-text :style="{ height:options.height}">
        <iframe
          :src="`data:application/pdf;base64,${pdf}`"
          style="padding: 0; margin: 0; height:100%; width:100%; border: none"
          seamless
          v-if="pdf !== null"
        ></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>

const defaults = {
  height: '800px',
  showButtons: true
}
export default {
  data: () => ({
    visible: false,
    pdf: null,
    options: defaults
  }),
  methods: {
    open (pdf, options) {
      this.visible = true
      this.pdf = pdf
      this.options = { ...defaults, ...options }
    },
    close () {
      this.visible = false
      this.pdf = null
    }
  }
}
</script>
