export default {
  methods: {
    triGram (txt) {
      const map = {}
      var s1 = (txt || '').toLowerCase()

      s1 = s1.replaceAll('č', 'c').replaceAll('ć', 'c').replaceAll('š', 's').replaceAll('ž', 'z').replaceAll('đ', 'd')

      if (s1.length === 1) {
        map[s1 + '  '] = true
        return map
      }

      if (s1.length === 2) {
        map[s1 + ' '] = true
        return map
      }

      const n = 3
      for (let k = 0; k <= s1.length - n; k++) map[s1.substring(k, k + n)] = true
      return map
    }
  }
}
